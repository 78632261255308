import Vue from "vue";
import VueRouter from "vue-router";
// import Home from '../views/Home.vue'
import Inversionista from "../views/Inversionista.vue";
import Franquiciatario from "../views/Franquiciatario.vue";
import FranquiciatarioForm from "../views/FranquiciatarioForm.vue";
import InversionistaForm from "../views/InversionistaForm.vue";
import VueGtag from "vue-gtag";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Franquiciatario,
    // component: Home
  },
  //   {
  //     path: "/franquiciatario",
  //     name: "Franquiciatario",
  //     component: Franquiciatario,
  //   },
  {
    path: "/franquiciatario/form",
    name: "franquiciatarioForm",
    component: FranquiciatarioForm,
    alias: [
      "/franquiciatario/form/step-one",
      "/franquiciatario/form/step-two",
      "/franquiciatario/form/step-three",
      "/franquiciatario/form/thank-you",
    ],
  },
  {
    path: "/inversionista/disabled",
    name: "Inversionista",
    component: Inversionista,
  },
  {
    path: "/inversionista/form/disabled",
    name: "inversonistaForm",
    component: InversionistaForm,
    alias: [
      "/inversionista/form/step-one/disabled",
      "/inversionista/form/step-two/disabled",
      "/inversionista/form/step-three/disabled",
      "/inversionista/form/thank-you/disabled",
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    document.getElementById("app").scrollIntoView();
  },
});

Vue.use(VueGtag, {
  config: {
    id: "G-N7HT1QM76R",
    params: {
      send_page_view: false,
    },
  },
  routes,
});

export default router;
