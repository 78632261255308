<template>
  <div class="enviado d-flex">
    <img
      src="@/assets/icons/Formulario/icono-solicitud-enviada.svg"
      alt="Icono correo enviado" />
    <p class="title">Tu solicitud ha sido enviada!</p>
    <p>Nos pondremos en contacto contigo para agendar una cita.</p>
    <router-link
      to="/"
      class="btn-init-link enviado__btn"
      v-on:click="limpiarLocal"
    >
      Regresar a Home
    </router-link>

    <noscript>
      <img
        height="1"
        width="1"
        src="https://www.facebook.com/tr?id=501776754146043&ev=PageView&noscript=1" />
    </noscript>
  </div>
</template>

<script>
import router from "../router/index";

export default {
  name: "CorreoEnviado",
  data: function () {
    history.pushState(null, "", "thank-you")
    return {
      route: router.currentRoute,
    };
  },
  methods: {
    limpiarLocal() {
      localStorage.clear();
      // console.log("Limpiando local storage");
    },
  },
};
</script>