<template>
  <div
    class="d-flex flex-column align-items-center pt-5 pb-5"
    style="background: #19191c"
  >
    <div class="d-flex justify-content-center align-items-center mt-5">
      <div
        class="col-md-8 d-flex justify-content-center flex-column align-items-center col-xs-12 pt-3"
      >
        <h5 class="sub-title text-center sub-title-panel">{{ subtitle }}</h5>
        <h1 class="text-center mb-4">{{ title }}</h1>
        <p
          class="text-center pb-4 px-4"
          :class="{
            'mb-0': type === 'experiencia',
            'mb-5': type !== 'experiencia',
          }"
          style="font-size: 20px"
        >
          {{ text }}
        </p>
      </div>
    </div>
    <div class="container">
      <div v-if="type == 'ventajas'" class="d-lg-flex justify-content-between">
        <Cards
          v-for="(element, index) in dataCardsVentajas"
          :key="index"
          v-bind:title="element.title"
          v-bind:text="element.text"
          v-bind:icon="element.icon"
        />
      </div>
      <div v-else-if="type == 'experiencia'" class="row flex-column">
        <!-- <div class="d-flex justify-content-between w-100 d-lg-none">
          <button
            class="regular"
            v-on:click="selectTab > 0 ? (selectTab -= 1) : selectTab"
            :disabled="selectTab == 0"
          >
            <img
              v-show="selectTab != 0"
              src="@/assets/icons/Inversionista/icono-flecha-izq.svg"
              width="40px"
            />
          </button>
          <button
            class="regular d-block d-xxl-none"
            v-on:click="selectTab < 3 ? (selectTab += 1) : selectTab"
            :disabled="tabs.length - 1 == selectTab"
          >
            <img
              v-show="selectTab != 3"
              src="@/assets/icons/Inversionista/icono-flecha-der-circle.svg"
              width="40px"
            />
          </button>
        </div>
        <Slider
          v-bind:tabs="tabs"
          v-bind:select="selectTab"
          v-bind:click="changeTab"
        /> -->
        <div class="dropdown text-center">
          <button
            class="btn-init-link dropdown-toggle w-100"
            style="max-width: 350px"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {{ tabs[selectTab] }}
          </button>
          <ul
            class="dropdown-items dropdown-menu w-100"
            style="max-width: 350px"
            aria-labelledby="dropdownMenuButton1"
          >
            <li v-for="(element, index) in tabs" :key="index">
              <a
                class="dropdown-item"
                @click="
                  () => {
                    changeTab(index);
                  }
                "
                >{{ element }}</a
              >
            </li>
          </ul>
        </div>
        <div class="position-relative px-0 px-lg-5">
          <CarrouselMobile class="d-lg-none" v-bind:tab="selectTab" />
          <Carrousel class="d-none d-lg-block" v-bind:tab="selectTab" />
        </div>
      </div>
      <div
        v-else-if="type == 'infoFinanciera' && subtype == 'Fra'"
        class="w-100 d-lg-flex justify-content-around container-card m-auto m-lg-0"
      >
        <Cards
          v-for="(element, index) in dataCardsInfo"
          :key="index"
          v-bind:title="element.title"
          v-bind:title2="element.title2"
          v-bind:title3="element.title3"
          v-bind:text="element.text"
          v-bind:icon="element.icon"
        />
      </div>
      <div
        v-else-if="type == 'infoFinanciera' && subtype == 'Inv'"
        class="row justify-content-around container-card"
      >
        <Cards
          v-for="(element, index) in dataCardsInv"
          :key="index"
          v-bind:title="element.title"
          v-bind:title2="element.title2"
          v-bind:text="element.text"
          v-bind:icon="element.icon"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Cards from "@/components/Card/CardListCenter.vue";
// import Slider from "@/components/Slider.vue";
import Carrousel from "@/components/Carrousel";
import CarrouselMobile from "@/components/CarrouselMobile";

import Mezcal from "@/assets/icons/home/icono-ventajas-especialistas-mezcal.svg";
import Intelligence from "@/assets/icons/home/icono-ventajas-business-intelligence.svg";
import Concepto from "@/assets/icons/home/icono-ventajas-concepto-innovador.svg";
import Exp from "@/assets/icons/home/icono-ventajas-marca-en-linea.svg";

import Canon from "@/assets/icons/franquiciatario/icono-informacion-financiera-canon-franquicia.svg";
import Financiera from "@/assets/icons/franquiciatario/icono-informacion-financiera-inversion-total.svg";
import Crecimiento from "@/assets/icons/franquiciatario/icono-informacion-financiera-periodo-recuperacion.svg";
import Regalias from "@/assets/icons/franquiciatario/icono-informacion-financiera-regalias-publicidad.svg";

export default {
  name: "PanelCenter",
  components: {
    Cards,
    // Slider,
    Carrousel,
    CarrouselMobile,
  },
  props: {
    type: String,
    title: String,
    subtitle: String,
    text: String,
    subtype: String,
  },
  data() {
    return {
      selectTab: 0,
      tabs: ["Sucursales", "Experiencia Mezcalera", "Menú", "Ambiente"],
      dataCardsVentajas: [
        {
          title: "Especialistas en Mezcal",
          text: "Alta especialización en mezcal y productos de mezcal de marca propia, siendo este un mercado en tendencia creciente y mucha demanda.",
          icon: Mezcal,
        },
        {
          title: "Business Intelligence",
          text: "Aplicación de tecnología y sistemas que nos ayudan a optimizar ventas y utilidades de las unidades de negocio, así como a innovar en la experiencia de consumo.",
          icon: Intelligence,
        },
        {
          title: "Concepto Innovador",
          text: "Nuestra identidad de marca es única y se diferencia rápidamente de la competencia, ya que cuidamos cada detalle de nuestros establecimientos.",
          icon: Concepto,
        },
        {
          title: "Marca en Expansión",
          text: "Somos una marca en expansión en etapa temprana por lo que invertir ahora representa una oportunidad para obtener rendimientos futuros exponenciales.",
          icon: Exp,
        },
      ],
      dataCardsInv: [
        {
          title2: "Inversión desde",
          title: "$125,000 MXN",
          icon: Financiera,
        },
        {
          title2: "A perturar",
          title: "1 Sucursal",
          icon: Canon,
          text: "en Lagos de Moreno, Jalisco",
        },
        {
          title2: "Inversión total requerida",
          title: "$1´250,000 MXN",
          icon: Regalias,
        },
        {
          title2: "Rendimiento anual estimado",
          title: "30%",
          icon: Crecimiento,
        },
      ],
      dataCardsInfo: [
        {
          title2: "Inversión",
          title: "$1’950,000 MXN",
          icon: Financiera,
          text: "(+IVA)",
        },
        {
          title2: "Periodo de recuperación",
          title: "30 a 36 meses",
          icon: Crecimiento,
        },
        {
          title2: "Regalías y publicidad",
          title3: "Las más bajas del mercado",
          title: "4%",
          // icon: Regalias,
          icon: Financiera,
        },
        {
          title2: "Canon de franquicia",
          title: "$200,000 MXN",
          icon: Canon,
          text: "(+IVA)",
        },
      ],
    };
  },
  computed: {
    imgUrl() {
      return {
        "background-image": "url('" + this.background + "')",
      };
    },
  },
  methods: {
    changeTab: function (index) {
      this.selectTab = index;
    },
    backTab: function () {
      //debugger;
      this.selectTab -= 1;
      // this.direction = "left";

      // console.log(this.direction);
    },
  },
};
</script>

<style lang="scss">
.btn-init-link {
  padding: 10px 30px !important;
}

.dropdown-items {
  border: solid 1.5px #f6cd42 !important;
  background-color: #f6cd42 !important;
  font-family: Roboto !important;
  font-size: 20px !important;
  color: black !important;
  text-decoration: none;
  text-align: center !important;
  text-wrap: nowrap;
}
</style>