<template>
  <div class="card card-dd mt-3 mb-5 pb-3 shadow m-auto m-lg-0">
    <div
      class="card-body d-flex flex-column justify-content-center align-items-center"
    >
      <img
        :src="icon"
        :style="title2 !== undefined ? 'width:120px' : 'width:100px'"
        class="my-3 my-md-5"
      />
      <p class="min text-center" :class="title3 ? 'mb-0' : ''">
        {{ title2 }}
      </p>
      <p class="min text-center">
        {{ title3 }}
      </p>
      <h4 class="card-title text-center mb-4">{{ title }}</h4>
      <p class="min text-center">
        {{ text }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardVentajas",
  props: {
    title: String,
    title2: String,
    title3: String,
    text: String,
    icon: String,
  },
};
</script>

<style lang="scss">
@media (min-width: 1024px) {
  .card-dd {
    width: 24%;
  }
}
</style>