<template>
  <div class="rendimiento p-3 pb-5" style="background-color: #19191c">
    <div class="container row no-gutters mb-5 sub-title-panel">
      <div class="rendimiento__info col col-lg-6 p-lg-0 m-lg-0">
        <h5 class="sub-title rendimiento__info__sub">Distribución de</h5>
        <h1 class="rendimiento__info__title">Inversión inicial</h1>
        <p class="rendimiento__info__texto">
          Sabemos que es muy importante conocer la información financiera antes
          de emprender un negocio, por eso te compartimos algunas proyecciones
          de tu inversión.
        </p>
        <p class="rendimiento__info__texto">
          Llena la solicitud de franquicia y con gusto te brindaremos
          información financiera detallada para que conozcas nuestro modelo de
          negocio.
        </p>
        <router-link
          tag="button"
          @click.native="gAnalytics"
          class="btn-init-link my-3 d-none d-sm-block d-md-block"
          :to="`franquiciatario/form/`"
        >
          <b>Quiero mi franquicia</b>
        </router-link>
      </div>
      <div class="rendimiento__calculadora col col-lg-6 p-lg-0 m-lg-0">
        <div>
          <!-- class="calculadora" -->
          <!-- <img
            class="calculadora__icono"
            src="@/assets/icons/franquiciatario/icono-informacion-financiera-inversion-total.svg"
            alt="icono distribucion de la inversion"
          />
          <h1 class="calculadora__titulo">Distribución de la inversión</h1>
          <p class="calculadora__subtitulo">
            * Contemplado para un local de 250 m2
          </p> -->
          <img
            class="calculadora__graph d-none d-sm-block d-md-block"
            src="@/assets/icons/franquiciatario/img-distrubicion-de-la-inversion.svg"
            alt="Grafico distribucion de la inversion"
          />
          <img
            class="calculadora__graph d-inline d-sm-none"
            src="@/assets/icons/franquiciatario/img-distrubicion-de-la-inversion-mobile.svg"
            alt="Grafico distribucion de la inversion"
          />
        </div>
        <router-link
          class="btn-process-sm btn-init-link d-inline d-sm-none"
          @click.native="gAnalytics"
          to="/franquiciatario/form/"
        >
          <b>Quiero mi franquicia</b>
          <img
            src="@/assets/icons/Inversionista/icono-flecha-der.svg"
            width="40px"
          />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
//import CardCalculadora from "../components/Card/CardCalculadora.vue"

export default {
  name: "Rendimiento",
  components: {
    //CardCalculadora
  },
  methods: {
    gAnalytics() {
      this.$gtag.event("franquiciatario/form", {
        event_category: "Cambio de pagina",
        event_label: "franquiciatario/form",
        value: 3,
      });
    },
  },
};
</script>

<style lang="scss">
.no-gutters {
  margin-right: 0 !important;
  margin-left: 0 !important;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}
</style>

