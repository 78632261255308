var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.selected ? 'card shadow' : '',style:({
    'max-width': '20rem',
    padding: 0,
    border: 0,
  })},[_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"grow"},[(!_vm.selected)?_c('span',{staticClass:"horizontal-line"}):_vm._e()]),_c('img',{staticClass:"mt-5 mb-5",staticStyle:{"width":"6rem"},attrs:{"src":_vm.icon}}),_c('div',{staticClass:"grow-2"},[(!_vm.selected)?_c('span',{staticClass:"horizontal-line"}):_vm._e()])]),_c('div',{staticClass:"card-body",style:({
      'padding-right': '3rem',
      'padding-left': '3rem',
    })},[_c('h4',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.stepNumber))]),(_vm.selected)?_c('h4',{staticClass:"card-title color-y"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_c('h4',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.text)+" ")]),_c('router-link',{staticClass:"btn-card btn-init-link px-4 d-none d-sm-block d-md-block",attrs:{"to":_vm.btnLink},nativeOn:{"click":function($event){return _vm.gAnalytics.apply(null, arguments)}}},[_c('b',[_vm._v(_vm._s(_vm.btnText))]),_c('img',{attrs:{"src":require("@/assets/icons/Inversionista/icono-flecha-der.svg"),"width":"40px"}})])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }