<template>
  <div
    :class="selected ? 'card shadow' : ''"
    :style="{
      'max-width': '20rem',
      padding: 0,
      border: 0,
    }"
  >
    <div class="d-flex align-items-center" style="width: 100%">
      <div class="grow">
        <span v-if="!selected" class="horizontal-line" />
      </div>
      <img :src="icon" style="width: 6rem" class="mt-5 mb-5" />
      <div class="grow-2">
        <span v-if="!selected" class="horizontal-line" />
      </div>
    </div>

    <div
      class="card-body"
      :style="{
        'padding-right': '3rem',
        'padding-left': '3rem',
      }"
    >
      <h4 class="card-title">{{ stepNumber }}</h4>

      <h4 v-if="selected" class="card-title color-y">
        {{ title }}
      </h4>

      <h4 v-else class="card-title">
        {{ title }}
      </h4>

      <p>
        {{ text }}
      </p>
      <!-- Aqui btn a formulario -->
      <router-link
        class="btn-card btn-init-link px-4 d-none d-sm-block d-md-block"
        :to="btnLink"
        @click.native="gAnalytics"
      >
        <b>{{ btnText }}</b>
        <img
          src="@/assets/icons/Inversionista/icono-flecha-der.svg"
          width="40px"
        />
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardProcessLine",
  props: {
    title: String,
    icon: String,
    text: String,
    stepNumber: String,
    selected: Boolean,
    action: Boolean,
    btnLink: String,
    btnText: String,
  },
  methods: {
    gAnalytics() {
      if(this.btnLink == '/inversionista/form'){
        this.$gtag.event('franquiciatario/form', {
        'event_category': 'Cambio de pagina',
        'event_label': 'franquiciatario/form',
        'value': 3
      })
      } else if(this.btnLink == '/franquiciatario/form'){
        this.$gtag.event('inversionista/form', {
        'event_category': 'Cambio de pagina',
        'event_label': 'inversionista/form',
        'value': 4
      })
      } else {
        console.log(this.btnLink);
      }
    }
  },
};
</script>