<template>
  <div
    class="row d-flex align-items-center justify-content-center pb-5 pt-5"
    style="background: #19191c"
  >
    <div class="row">
      <div
        class="col-md-6 col-xs-12 d-flex align-items-center justify-content-center"
      >
        <img
          src="@/assets/img/Inversionista/img-header-home-inversionista.jpg"
          width="90%"
          style="border-radius: 5px"
        />
      </div>
      <div class="col-md-6 col-xs-12 align-items-center estilo-vida-div">
        <div class="col-md-8 col-xs-11 m-5 estilo-vida-texto">
          <h5 class="sub-title my-3">Estilo de vida</h5>
          <h1 class="my-3">{{ title }}</h1>
          <p class="my-3">
            Te estás convirtiendo en un empresario que ademas de generar
            rendimientos, promueve la cultura del mezcal, es dueño de su tiempo
            libre y del mejor lugar para disfrutar en compañía de amigos y
            familia.
            <!-- <br />
            <br />
            <b>¡Únete al equipo!</b> -->
          </p>
          <router-link
            tag="button"
            class="btn-init-link my-3"
            @click.native="gAnalytics"
            :to="`${route.path}/form`"
          >
            <b>Quiero mi franquicia</b>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "../router/index";
export default {
  name: "EstiloVida",
  props: {
    title: String,
  },
  methods: {
    gAnalytics() {
      if (this.btnLink == "/inversionista/form") {
        this.$gtag.event("franquiciatario/form", {
          event_category: "Cambio de pagina",
          event_label: "franquiciatario/form",
          value: 3,
        });
      } else if (this.btnLink == "/franquiciatario/form") {
        this.$gtag.event("inversionista/form", {
          event_category: "Cambio de pagina",
          event_label: "inversionista/form",
          value: 4,
        });
      } else {
        console.log(this.btnLink);
      }
    },
  },
  data() {
    return {
      route: router.currentRoute,
    };
  },
};
</script>