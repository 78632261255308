<template>
  <div
    class="d-flex flex-column justify-content-start pt-2"
    style="font-size: 18px"
  >
    <div
      class="d-flex justify-content-start align-items-end historiaSlide__encabezado"
    >
      <!-- <p class="title">{{ data[select].title }}</p>
      <p class="">{{ data[select].subtitle }}</p> -->
      <p class="title">{{ select.year }}</p>
      <!-- <p class="">{{ select.year }}</p> -->
    </div>
    <p class="text-left pb-lg-4" v-html="select.description"></p>
    <!-- <p v-if="select == 1" class="text-left pb-4">
      Con el creciente éxito de la marca y el modelo de negocio, se abre una
      segunda sucursal en
      <i class="yellow">Manzanillo, Colima.</i>
    </p>
    <p v-if="select == 2" class="text-left pb-4">
      Debido a la alta demanda por parte de nuestros clientes y a la critica
      positiva seguimos expandiendo La Mezcantina, por lo cual abrimos una
      tercera sucursal en
      <i class="yellow"> Tlaquepaque, Jalisco.</i>
    </p>
    <p v-if="select == 3" class="text-left pb-4">
      Seguimos innovando nuestra propuesta de valor y nos convertimos en un
      modelo de franquicia aprobado y aceptado.
      <br />
      Aperturamos nuestra cuarta unidad de negocio bajo el modelo de franquicia
      en
      <i class="yellow"> Naucalpan, Estado de México</i> y seguimos en
      crecimiento a nivel nacional.
    </p> -->
  </div>
</template>

<script>
export default {
  name: "HistoriaSlide",
  props: {
    select: Object,
  },
  data() {
    return {
      data: {
        0: {
          title: "Primera sucursal",
          subtitle: "(Prueba piloto)",
        },
        1: {
          title: "Segunda sucursal",
          subtitle: "(Expansión local)",
        },
        2: {
          title: "Tercera sucursal",
          subtitle: "(Expansión nacional)",
        },
        3: {
          title: "Formalización modelo de franquicia y expansión nacional",
          subtitle: "",
        },
      },
    };
  },
};
</script>