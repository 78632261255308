<template>
  <div
    class="d-flex panel-compromiso flex-column align-items-center pt-5 pb-5"
    style="background: #19191c"
  >
    <div class="container mb-5">
      <div class="row justify-content-center align-items-center">
        <div
          class="col-md-12 d-flex justify-content-center flex-column align-items-center col-xs-12 pt-3"
        >
          <h5 class="sub-title text-center compromiso-titulo">
            Socios franquiciatarios
          </h5>
          <h1 class="text-center pb-5">Nuestro compromiso</h1>
        </div>
        <div
          class="col-xs-12 col-md-6 divider-1 d-flex flex-column align-items-end justify-content-center"
        >
          <div class="d-flex p-3 col-xs-12 col-md-8 me-lg-5">
            <p class="col-1 d-flex text-left align-items-start">01</p>
            <div class="d-flex flex-column">
              <b class="color-w fs-5 mb-2">Transmitir el “Know How” </b>
              <p class="col-xs-11 col-md-8 col-lg-12">
                Brindamos a nuestros franquiciatarios la experiencia empresarial
                y conocimiento especializado que hemos adquirido a través de los
                años para recortar la curva de aprendizaje.
              </p>
            </div>
          </div>
          <div class="d-flex p-3 col-xs-12 col-md-8 me-lg-5">
            <p class="col-1 d-flex text-left align-items-start">02</p>
            <div class="d-flex flex-column">
              <b class="color-w fs-5 mb-2">Retroalimentación constante</b>
              <p class="col-xs-11 col-md-8 col-lg-12">
                Sabemos que las mejores ideas surgen de la comunicación
                constante por lo que fomentamos la retroalimentación con
                nuestros franquiciatarios con el fin de mejorar nuestros
                productos y servicios y que todos ganemos más.
              </p>
            </div>
          </div>
        </div>
        <div
          class="col-xs-12 col-md-6 d-flex flex-column align-items-start justify-content-center"
        >
          <div class="d-flex p-3 col-xs-12 col-md-8 ms-lg-5">
            <p class="col-1 d-flex text-left align-items-start">03</p>
            <div class="d-flex flex-column">
              <b class="color-w fs-5 mb-2">Innovación continua </b>
              <p class="col-xs-11 col-md-8 col-lg-12">
                Contamos con un área de innovación y desarrollo de nuevos
                productos, servicios y sistemas para que nuestro concepto sea
                innovador, tecnológico y que permanezca vigente a largo plazo.
              </p>
            </div>
          </div>
          <div class="d-flex p-3 col-xs-12 col-md-8 ms-lg-5">
            <p class="col-1 d-flex text-left align-items-start">04</p>
            <div class="d-flex flex-column">
              <b class="color-w fs-5 mb-2">Apoyo en actividades clave </b>
              <p class="col-xs-11 col-md-8 col-lg-12">
                Apoyamos a nuestros franquiciatarios en todas las actividades
                clave para la operación y administración del negocio a través de
                un equipo multidisciplinario.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contacto",
};
</script>

<style lang="scss">
.color-w {
  line-height: 0.9;
}
</style>