<template>
  <div class="home">
    <PanelTop
      title="Opera y administra tu propia franquicia de La Mezcantina"
      subtitle="Franquicia rentable en México"
      text="Conviértete en un empresario que promueve la cultura del mezcal generando rendimientos a través de un modelo de negocio único, disruptivo y con mucho crecimiento"
      buttonText="Quiero mi franquicia"
      :background="img"
    />
    <PanelCenter
      type="ventajas"
      title="¿Por qué la Mezcantina?"
      subtitle="Ventajas competitivas"
      text="Queremos ser la franquicia de restaurantes mas rentable de México, por lo que diseñamos un modelo que se basa en 4 pilares:"
    />
    <PanelCenter
      type="experiencia"
      title="Experiencia La Mezcantina"
      subtitle=""
      text="Vive una experiencia extraordinaria a través de un excelente ambiente y servicio, así como deliciosas comidas y bebidas regionales"
    />
    <Historia />
    <PanelCenter
      type="infoFinanciera"
      subtype="Fra"
      title="Información financiera"
      subtitle="Invierte en La Mezcantina"
      text="Al adquirir una franquicia de La Mezcantina estas invirtiendo tu capital en un negocio rentable, innovador y con mucho crecimiento"
    />
    <DistInversion />
    <ProcessLine
      title="¿Cómo seria el proceso si Quiero mi franquicia?"
      subtitle="Conoce el proceso"
      btnLink="franquiciatario/form/"
    />
    <Compromiso />
    <PanelRight type="Distr" title="Mi inversión" subtitle="Distribución de " />
    <PanelRight
      type="Preguntas"
      title="Preguntas importantes"
      subtitle="Antes de invertir en La Mezcantina "
    />
    <PanelTop
      title="Al adquirir una franquicia de La Mezcantina"
      text="Te estás convirtiendo en un empresario que ademas de generar
            rendimientos, promueve la cultura del mezcal, es dueño de su tiempo
            libre y del mejor lugar para disfrutar en compañía de amigos y
            familia."
      buttonText="Quiero mi franquicia"
      :background="img2"
    />
    <!-- <EstiloVida title="Al adquirir una franquicia de La Mezcantina" /> -->
    <!-- <Contacto /> -->

    <!-- Google Tag Manager (noscript) -->
    <noscript>
      <iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-MD3HMDH"
        height="0"
        width="0"
        style="display: none; visibility: hidden"
      ></iframe>
    </noscript>
  </div>
</template>

<script>
import PanelTop from "@/components/PanelTop.vue";
import PanelCenter from "@/components/PanelCenter.vue";
import PanelRight from "../components/PanelRight.vue";
import Img from "@/assets/img/home/header-home.jpg";
import Img2 from "@/assets/img/Inversionista/img-header-home-inversionista.jpg";
// import EstiloVida from "../components/EstiloVida.vue";
// import Contacto from "../components/Contacto.vue";
import DistInversion from "../components/DistInversion.vue";
import Compromiso from "../components/Compromiso.vue";
import ProcessLine from "../components/ProcessLine.vue";
import Historia from "@/components/Historia.vue";

export default {
  name: "Franquicitario",
  components: {
    PanelTop,
    PanelCenter,
    PanelRight,
    // EstiloVida,
    Historia,
    // Contacto,
    Compromiso,
    ProcessLine,
    DistInversion,
  },
  data: function () {
    localStorage.clear();
    // console.log("cleaning localStorage");
    return {
      img: Img,
      img2: Img2,
    };
  },
};
</script>