<template>
  <div class="w-100">
    <QuestionItem
      v-for="(element, index) in type == 'inversionista'
        ? inversionsitaQuestions
        : franquiciatarioQuestions"
      :key="index"
      v-bind:index="index"
      v-bind:tab="tab"
      v-bind:numQuestions="type == 'inversionista'
        ? inversionsitaQuestions.length
        : franquiciatarioQuestions.length"
      v-bind:type="element.type"
      v-bind:question="element.question"
      v-bind:options="element.options"
      v-bind:placeholder="element.placeholder"
    />
  </div>
</template>

<script>
import QuestionItem from "@/components/QuestionItem.vue";
import IconPronto from "../assets/icons/Formulario/icono-lo-mas-pronto.svg";
import IconNo from "../assets/icons/Formulario/icono-no.svg";
import IconSi from "../assets/icons/Formulario/icono-si.svg";
import IconAnioMas from "../assets/icons/Formulario/icono-1-ano-o-mas.svg";
import IconPropio from "../assets/icons/Formulario/icono-propios.svg";
import IconSociedad from "../assets/icons/Formulario/icono-en-sociedad.svg";
import IconGerente from "../assets/icons/Formulario/icono-gerente.svg";
import IconOtros from "../assets/icons/Formulario/icono-otros.svg";
import IconPoca from "../assets/icons/Formulario/icono-poca-inversion.svg";
import IconMedia from "../assets/icons/Formulario/icono-media-inversion.svg";
import IconMucha from "../assets/icons/Formulario/icono-mucha-inversion.svg";
import IconProximo from "../assets/icons/Formulario/icono-proximamente.svg";

export default {
  name: "ProjectDetailsForm",
  components: {
    QuestionItem,
  },
  props: {
    type: String,
    tab: Number,
  },
  data: function () {
    if (location.pathname !== "/franquiciatario/form/step-two" ||
      location.pathname !== "/inversionista/form/step-two") {
      history.pushState(null, '', 'step-two');
    }
    return {
      validado: 'false',
      franquiciatarioQuestions: [
        {
          type: "text",
          question:
            "¿En qué ciudad y colonia te interesaría poner una mezcantina?",
          placeholder: "Ciudad y colonia",
        },
        {
          type: "options",
          question:
            "¿Cuentas ya con algun local donde te gustaría poner una mezcantina?",
          options: [
            { text: "sí", icon: IconSi },
            { text: "no", icon: IconNo },
          ],
        },
        {
          type: "options",
          question: "¿En cuánto tiempo te gustaría inciar tu proyecto?",
          options: [
            { text: "lo mas pronto posible", icon: IconPronto },
            { text: "En los proximos 3 a 6 meses", icon: IconProximo },
            { text: "En los proximos 7 a 12 meses", icon: IconProximo },
            { text: "Dentro de un año o más", icon: IconAnioMas },
          ],
        },
        {
          type: "options",
          question:
            "¿Los recursos que pretendes invertir son propios o en sociedad con otras personas?",
          options: [
            { text: "Propios", icon: IconPropio },
            { text: "En sociedad con otras personas", icon: IconSociedad },
          ],
        },
        {
          type: "options",
          question: "¿Quién operaría tu franquicia de la mezcantina?",
          options: [
            { text: "Yo", icon: IconPropio },
            { text: "Mis socio(s)", icon: IconSociedad },
            { text: "Gerente operativo / administrativo", icon: IconGerente },
            { text: "Otros", icon: IconOtros },
          ],
        },
        {
          type: "textarea",
          question:
            "¿Qué es lo que esperas al formar parte de nuestra franquicia?",
          placeholder: "Cuentanos",
        },
      ],
      inversionsitaQuestions: [
        {
          type: "options",
          question: "¿En cuánto tiempo te gustaría invertir?",
          options: [
            { text: "En los proximos 30 días", icon: IconPronto },
            { text: "De 1 a 2 meses", icon: IconProximo },
            { text: "De 2 a 3 meses", icon: IconProximo },
            { text: "Más de 3 meses", icon: IconAnioMas },
          ],
        },
        {
          type: "options",
          question: "¿Cuánto te gustaría invertir?",
          options: [
            { text: "$100,000 a $199,999 MXN", icon: IconPoca },
            { text: "$200,000 a $499,999 MXN", icon: IconPoca },
            { text: "$500,000 a $999,999 MXN", icon: IconMedia },
            { text: "$1,000,000 o más", icon: IconMucha },
          ],
        },
        {
          type: "options",
          question:
            "¿Los recursos que pretendes invertir son propios y/o en sociedad con otras personas?",
          options: [
            { text: "Propios", icon: IconPropio },
            { text: "En sociedad con otras personas", icon: IconSociedad },
          ],
        },
        {
          type: "textarea",
          question:
            "¿Qué es lo que más te gustó de nuestro modelo de inversión?",
          placeholder: "Cuentanos",
        },
      ],
    };
  },
  methods: {},
};
</script>
