<template>
  <div class="panel historia-lg" style="background-color: #000000">
    <div class="container d-lg-flex alight-items-center py-5">
      <div class="row m-lg-0 my-5">
        <div class="col-md-6 col-xs-12 p-3 historia-div">
          <h5 class="sub-title">Conoce</h5>
          <h1 class="pb-3 pb-md-5">Nuestra Historia</h1>
          <div class="container-card2">
            <!-- <div class="d-flex justify-content-between w-100 d-lg-none">
              <button
                class="regular"
                v-on:click="selectTab > 0 ? (selectTab -= 1) : selectTab"
                :disabled="selectTab == 0"
              >
                <img
                  v-show="selectTab != 0"
                  src="@/assets/icons/Inversionista/icono-flecha-izq.svg"
                  width="40px"
                />
              </button>
              <button
                class="regular d-block d-xxl-none"
                v-on:click="selectTab < 5 ? (selectTab += 1) : selectTab"
                :disabled="5 == selectTab"
              >
                <img
                  v-show="selectTab != 5"
                  src="@/assets/icons/Inversionista/icono-flecha-der-circle.svg"
                  width="40px"
                />
              </button>
            </div>
            <Slider
              type="Historia"
              v-bind:tabs="tabs"
              v-bind:select="selectTab"
              v-bind:click="changeTab"
            /> -->

            <div class="dropdown text-center text-lg-start">
              <button
                class="btn-init-link dropdown-toggle w-100"
                style="max-width: 350px"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {{ tabs[selectTab].title }}
              </button>
              <ul
                class="dropdown-items dropdown-menu w-100"
                style="max-width: 350px"
                aria-labelledby="dropdownMenuButton1"
              >
                <li v-for="(element, index) in tabs" :key="index">
                  <a
                    class="dropdown-item"
                    @click="
                      () => {
                        changeTab(index);
                      }
                    "
                    >{{ element.title }}</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="position-relative px-0 pt-3">
            <HistoriaSlide v-bind:select="tabs[selectTab]" />
          </div>
        </div>
        <div
          class="col-md-6 col-xs-12 d-flex align-items-center justify-content-center p-lg-3"
        >
          <img
            :src="
              require(`@/assets/img/franquiciatario/${tabs[selectTab].image}`)
            "
            width="100%"
            style="border-radius: 20px"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Slider from "@/components/Slider.vue";
import HistoriaSlide from "@/components/HistoriaSlide.vue";

export default {
  name: "Historia",
  components: {
    // Slider,
    HistoriaSlide,
  },
  data() {
    return {
      selectTab: 0,
      // tabs: ["2018", "2019", "2020", "2021", "2022", "2023"],
      tabs: [
        {
          title: "Nuestros Comienzos",
          btnStyle: "",
          year: "2018",
          description: `La primera sucursal de La Mezcantina se inauguró
          con un concepto innovador que combinaba lo
          tradicional de una cantina mexicana con un
          restaurante de alta calidad, ofreciendo una
          experiencia única centrada en el mezcal, en
          Guadalajara, Jalisco.
          <br />
          <br />
          Este lanzamiento marcó el comienzo de una
          aventura que revolucionaría el concepto de
          mezcalería.`,
          image: "HistoryOne.jpg",
        },
        {
          title: "Expansión Regional",
          btnStyle: "",
          year: "2019-2020",
          description: `Tras el éxito de nuestra primera ubicación, La
          Mezcantina rápidamente se ganó el corazón de la gente,
          llevándonos a expandir nuestras fronteras.
          En 2019, inauguramos una nueva sucursal en Manzanillo,
          <br />
          <br />
          Colima, seguida de una tercera en Tlaquepaque, Jalisco.
          Esta expansión fue impulsada por la demanda creciente
          de nuestros clientes que nos consolidaron como un
          referente en la región.`,
          image: "HistoryTwo.jpg",
        },
        {
          title: "Innovación y Franquicias",
          btnStyle: "width: 180px;",
          year: "2022-2024",
          description: `Enfocados en optimizar y replicar nuestro exitoso
          modelo, desarrollamos un sistema de franquicias,
          integrando tecnologías de punta en áreas críticas como
          marketing, recursos humanos y finanzas. Esta evolución
          nos permitió automatizar procesos y asegurar una
          gestión eficiente de todos los recursos sin precedentes
          en el sector.`,
          image: "HistoryThree.jpg",
        },
        {
          title: "El Presente de La Mezcantina",
          btnStyle: "width: 180px;",
          year: "",
          description: `Hoy, La Mezcantina es mucho más que un restaurante-bar;
          es una marca líder en cada región donde opera, reconocida
          por su calidad y autenticidad. Continuamos innovando y
          automatizando procesos con tecnología para facilitar aún
          más la gestión de nuestras franquicias, con miras a una
          expansión nacional.
          <br />
          <br />
          SOMOS LA MEZCALERÍA DE MÉXICO.`,
          image: "HistoryFour.jpg",
        },
      ],
    };
  },
  methods: {
    changeTab: function (index) {
      this.selectTab = index;
    },
  },
};
</script>

<style lang="scss">
.dropdown {
  .btn-init-link {
    padding: 10px 30px !important;
    border: solid 2px #f6cd42 !important;
    background-color: #19191c !important;
    color: #f6cd42 !important;
  }
}

.dropdown-items {
  border: solid 1.5px #f6cd42 !important;
  background-color: #f6cd42 !important;
  font-family: Roboto !important;
  font-size: 20px !important;
  color: black !important;
  text-decoration: none;
  text-align: center !important;
  text-wrap: nowrap;
}
</style>