<template>
  <div
    class="d-flex row flex-column align-items-center pt-5 pb-5"
    style="background: #19191c"
  >
    <div class="d-flex row justify-content-center align-items-center">
      <div
        class="col-md-12 d-flex justify-content-center flex-column align-items-center col-xs-12 pt-3"
      >
        <h5 class="sub-title text-center">Contáctanos</h5>
        <h1 class="text-center">Queremos saber de ti</h1>
        <p class="text-center pb-4">
          Con gusto resolvemos tus dudas sobre nuestro concepto y modelo de
          negocio
        </p>
      </div>
      <div
        class="col-xs-12 col-md-4 divider d-flex flex-column align-items-center justify-content-center contact p-5"
      >
        <img
          class="col"
          src="@/assets/icons/Inversionista/icono-telefono.svg"
          width="40px"
        />
        <!-- <b class="color-w mt-3 contacto__telefono">33 2312 1591</b> -->
      </div>
      <div
        class="col-xs-12 col-md-4 d-flex flex-column align-items-center justify-content-center contact p-5"
      >
        <img
          class="col"
          src="@/assets/icons/Inversionista/icono-mail.svg"
          width="40px"
        />
        <b class="color-w mt-3 contacto__correo">franquicias@lamezcantina.mx</b>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contacto",
};
</script>
