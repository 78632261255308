<template>
  <div>
    <PanelTop
      title="Hazte socio de la Mezcantina"
      subtitle="Socio inversionista"
      text="Obtén rendimiento de tu inversión y sé accionista de nuestra proxima sucursal Lagos de Moreno"
      :background="img"
    />
    <ModeloInversion />
    <PanelCenter
      type="infoFinanciera"
      subtype="Inv"
      title="Información finaciera"
      subtitle="Invierte en La Mezcantina"
      text="Al invertir en la Mezcantina cuentas con el respaldo y experiencia de una franquicia rentable, innovadora y con mucho crecimiento"
    />
    <Rendimiento />
    <PanelRight
      type="Preguntas"
      title="Preguntas importantes"
      subtitle="Antes de invertir en La Mezcantina "
    />
    <ProcessLine
      title="¿Como seria el proceso si Quiero mi franquicia?"
      subtitle="Conoce el proceso"
      type="inv"
      btnLink="inversionista/form"
    />
    <EstiloVida title="Al invertir en La Mezcantina" />
    <Contacto />

    <!-- Google Tag Manager (noscript) -->
    <noscript>
      <iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-MD3HMDH"
        height="0"
        width="0"
        style="display: none; visibility: hidden"
      ></iframe>
    </noscript>
  </div>
</template>

<script>
import PanelTop from "@/components/PanelTop.vue";
import Img from "@/assets/img/Inversionista/img-header-home-inversionista.jpg";
import PanelCenter from "../components/PanelCenter.vue";
import PanelRight from "../components/PanelRight.vue";
import Rendimiento from "../components/Rendimiento.vue";
import EstiloVida from "../components/EstiloVida.vue";
import Contacto from "../components/Contacto.vue";
import ProcessLine from "../components/ProcessLine.vue";
import ModeloInversion from "../components/ModeloInversion.vue";

export default {
  name: "Inversionista",
  components: {
    PanelTop,
    PanelCenter,
    PanelRight,
    EstiloVida,
    Contacto,
    ProcessLine,
    ModeloInversion,
    Rendimiento,
  },
  data: function () {
    localStorage.clear();
    // console.log("cleaning localStorage");
    return {
      img: Img,
    };
  },
};
</script>