<template>
  <div
    class="panel-right pb-5 pt-5"
    :style="type === 'Distr' ? ' background: #19191c' : 'background: #19191c'"
  >
    <div class="container row d-flex align-items-center m-auto mb-5">
      <div class="col-md-6 col-xs-12 p-lg-5 preguntas">
        <h5 class="sub-title">{{ subtitle }}</h5>
        <h1 class="panelright-titulo">{{ title }}</h1>
        <div v-if="type == 'Distr'" class="col-md-8 col-xs-12">
          <p class="text-left">
            Sabemos que es muy importante conocer la información financiera
            antes de emprender un negocio, por eso te compartimos algunas
            proyecciones de tu inversión.
            <br />
            <br />
            Llena la solicitud de franquicia y con gusto te brindaremos
            información financiera detallada para que conozcas nuestro modelo de
            negocio.
          </p>
          <p>{{ router.path }}</p>
          <router-link
            tag="button"
            class="btn-init"
            @click.native="gAnalytics"
            :to="`${route.path}/form`"
          >
            <b>Quiero mi franquicia</b>
            <img
              src="@/assets/icons/Inversionista/icono-flecha-der.svg"
              width="40px"
            />
          </router-link>
        </div>
        <div v-else-if="route.path == '/'" class="col-md-12 col-xs-12">
          <!-- <div class="col-12" id="accordion"> -->
          <CardPregunta
            v-for="(element, index) in preguntasFran"
            :key="index"
            v-bind:pregunta="element.pregunta"
            v-bind:texts="element.texts"
            v-bind:numero="index"
          />
          <!-- </div> -->
        </div>

        <div v-if="route.path == '/inversionista'" class="col-md-12 col-xs-12">
          <CardPregunta
            v-for="(element, index) in preguntasInv"
            :key="index"
            v-bind:pregunta="element.pregunta"
            v-bind:texts="element.texts"
            v-bind:numero="index"
          />
        </div>
      </div>
      <div class="col-md-6 col-xs-12 d-none d-md-block">
        <img
          :src="getImg"
          width="100%"
          style="border-radius: 10px"
          class="centrar-imagen img-opacity"
        />
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
import ImgDistribucion from "@/assets/icons/franquiciatario/img-distrubicion-de-la-inversion.svg";
// import ImgPreguntas from "@/assets/img/Inversionista/img-preguntas-importantes.jpg";
import ImgPreguntas from "@/assets/img/Inversionista/img-invertir-en-la-mezcantina.jpg";
import CardPregunta from "./Card/CardPregunta.vue";
import router from "../router/index";

export default {
  name: "PanelRight",
  props: {
    type: String,
    title: String,
    subtitle: String,
  },
  components: {
    CardPregunta,
  },
  data: function () {
    return {
      imgD: ImgDistribucion,
      imgP: ImgPreguntas,
      preguntasFran: {
        "01": {
          pregunta: "¿Qué incluye la franquicia?",
          texts: [
            "Acompañamiento y asesoramiento para la selección y negociación del local ",
            "Proyecto arquitectónico y ejecutivo de adaptaciones del local con el costo unitario",
            "Ejecución del proyecto de remodelación y entrega llave en mano de la sucursal lista para operar",
            "Información y proyecciones financieras del negocio La Mezcantina®",
            "Capacitación a la plantilla inicial de trabajo",
            "Manual de procesos operativos y administrativos completo de La Mezcantina®",
            "Transmisión completa de la imagen de marca La Mezcantina ®",
            "Listado de proveedores de insumos y servicios",
            "Menú La Mezcantina® con recetario, porciones, costos y precios de venta",
            "Asistencia y soporte por el corporativo en la operación continua del negocio",
          ],
        },
        "02": {
          pregunta: "¿Cómo saber si esta franquicia es para mí?",
          texts: [
            "Capacidad financiera para realizar inversión sin que afecte su patrimonio",
            "Afinidad por la imagen, productos y servicios de La Mezcantina",
            "Gusto por promover la cultura y tradición del mezcal",
            "Gusto por ambiente de trabajo nocturno",
            "Deseo de adquirir más experiencia en el sector gastronómico y nocturno",
            "Disposición a aprender nuevas habilidades y conocimientos.",
          ],
        },
        "03": {
          pregunta:
            "¿Qué habilidades y conocimientos se requieren para operar una Mezcantina?",
          texts: [
            "Proactividad e iniciativa para resolver problemas",
            "Inteligencia emocional en los negocios",
            "Conocimiento administrativo y financiero básico",
            "Experiencia previa en la industria de restaurantes y bares",
            "Nociones básicas de servicio a clientes y hospitalidad",
            "Disposición de ser asesorado y seguir prácticas sugeridas por el franquiciante",
            "Disposición a trabajar duro y correr riesgos financieros",
            "Facilidad de uso de tecnología y sistemas",
          ],
        },
        "04": {
          pregunta:
            "¿Nos ayudan con la capacitación de personal y el inicio de operaciones?",
          texts: [
            "Claro! te ayudamos en las contrataciones y también te capacitamos a ti y a tu gerente en cómo contratar al personal adecuado y cómo capacitarlo",
            "Además nos encargamos de capacitar a tu primer plantilla de personal y nuestro equipo de aperturas te estará apoyando durante todo el proceso de inicio de operaciones.",
          ],
        },
        "05": {
          pregunta: "¿Ustedes eligen el local o el franquiciatario?",
          texts: [
            "Antes de elegir el local hacemos un estudio de mercado, donde determinamos las zonas con mayores probabilidades de éxito, ya que una buena ubicación es clave.",
            "Después buscamos en conjunto contigo locales dentro de esas zonas, te ayudamos con las negociaciones con los arrendadores y en conjunto decidimos cual es la mejor opción.",
            "En caso de que tu ya cuentas con un local o tengas preferencia por uno en específico, realizamos un estudio para verificar que tan viable es, en caso que no sea viable te damos una lista de posibles opciones para que puedas escoger el que más te guste.",
          ],
        },
      },
      preguntasInv: {
        "01": {
          pregunta:
            "¿Cómo afectó la pandemia a su modelo de negocio y que pasaría si volvemos a cuarentena?",
          texts: [
            "Gracias a la pandemia hemos desarrollado una serie de herramientas tecnológicas que nos han ayudado a aumentar la rentabilidad y abrir nuevos canales de venta digital para seguir generando rendimientos con aforos limitados y restricciones de horario.",
            "En caso de que volviéramos a cuarentena las sucursales están equipadas para vender en línea y por plataformas digitales, asegurando un flujo constante a pesar de las limitaciones.",
          ],
        },
        "02": {
          pregunta: "¿Donde se ubicará la sucursal en la que invierto?",
          texts: [
            "En la ciudad de Lagos de Moreno, Jalisco, a unas cuadras de la plaza capuccinas (la plaza mas importante de la ciudad), en una plaza comercial con amplio estacionamiento, buen flujo peatonal y vehicular, la cual cuenta con tiendas ancla como BBVA.",
          ],
        },
        "03": {
          pregunta:
            "¿Quiero mi franquicia en una sucursal que se encuentre en la ciudad donde actualmente vivo, que se puede hacer?",
          texts: [
            "Para sucursales fuera de la ciudad de Guadalajara contamos con el modelo de franquicia, en donde una persona o grupo de personas pueden operar y administrar su propia franquicia de La Mezcantina.",
            "En el modelo de CrowdFunding por el momento solo tenemos aperturas disponibles para Guadalajara, posteriormente estaremos abriendo en nuevas ciudades.",
          ],
        },
        "04": {
          pregunta:
            "¿Que pasa si no se completa el 100% de inversión requerida?",
          texts: [
            "Nuestro modelo funciona a través de rondas de inversión:",
            "Abrimos una ronda de inversión con duración de 3 meses, durante ese periodo se busca completar el 100% del capital requerido.",
            "En caso de que finalizara la ronda y no se obtenga el financiamiento completo, se abre una segunda ronda de inversión, con opción a retirar el anticipo sin penalización o continuar en la siguiente ronda",
            "Al momento de completar el 100% del capital requerido se inicia el proyecto.",
          ],
        },
        "05": {
          pregunta:
            "¿Como tengo que estar dado de alta en el SAT para poder invertir?",
          texts: [
            "Para poder formar parte de la asamblea de accionistas de La Mezcantina tienes que estar dado de alta como persona física con actividad empresarial o como persona moral.",
          ],
        },
      },
      route: router.currentRoute,
    };
  },
  computed: {
    getImg() {
      if (this.type == "Distr") {
        return this.imgD;
      } else {
        return this.imgP;
      }
    },
  },
  methods: {
    gAnalytics() {
      if (this.currentRouteName == "/inversionista") {
        this.$gtag.event("franquiciatario/form", {
          event_category: "Cambio de pagina",
          event_label: "franquiciatario/form",
          value: 3,
        });
      } else if (this.currentRouteName == "/franquiciatario") {
        this.$gtag.event("inversionista/form", {
          event_category: "Cambio de pagina",
          event_label: "inversionista/form",
          value: 4,
        });
      } else {
        console.log(this.btnLink);
      }
    },
  },
};
</script>