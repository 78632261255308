<template>
  <div
    class="container-fluid header d-flex justify-content-center position-fixed px-3"
    style="z-index: 9999"
  >
    <div class="container d-flex justify-content-between py-3 px-0" fluid>
      <div class="header-left">
        <router-link class="d-md-none" to="/" @click="gAnalytics">
          <!-- Logo isotipo -->
          <img
            src="@/assets/icons/Formulario/logo-la-mezcantina.svg"
            width="50"
          />
        </router-link>
        <router-link class="d-none d-md-block" to="/" @click="gAnalytics">
          <!-- Logotipo -->
          <img
            src="@/assets/icons/Inversionista/logo-la-mezcantina-top-menu.svg"
            width="80"
          />
        </router-link>
      </div>
      <div
        class="header-right"
        v-if="!buttonExcludedPages.includes($route.path)"
      >
        <a
          class="btn-whats fw-bolder me-3 d-none d-lg-inline"
          href="https://wa.me/523323121591"
          target="_blank"
        >
          <img
            class="filter-white me-2"
            src="@/assets/icons/whatsapp.svg"
            alt=""
            width="24"
          />
          WhatsApp</a
        >
        <router-link
          class="btn-link"
          tag="button"
          style="text-wrap: nowrap"
          @click.native="gAnalytics"
          :to="`/franquiciatario/form/`"
        >
          <b>Quiero mi franquicia</b>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "header",
  data() {
    return {
      buttonExcludedPages: [
        "/inversionista/form/",
        "/franquiciatario/form/",
        "/franquiciatario/form/step-one",
        "/franquiciatario/form/step-two",
        "/franquiciatario/form/step-three",
        "/franquiciatario/form/thank-you",
      ],
    };
  },
  methods: {
    gAnalytics() {
      if (this.$route.path == "/inversionista") {
        this.$gtag.event("franquiciatario/form", {
          event_category: "Cambio de pagina",
          event_label: "franquiciatario/form",
          value: 3,
        });
      } else if (this.$route.path == "/franquiciatario") {
        this.$gtag.event("inversionista/form", {
          event_category: "Cambio de pagina",
          event_label: "inversionista/form",
          value: 4,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.header {
  background-color: #19191c;
  .btn-link {
    padding: 0 20px;
    box-shadow: 0 3px 6px 0 rgba(165, 165, 61, 0);
    border: solid 1.5px #f6cd42;
    background-color: #f6cd42;
    border-radius: 20px;
    font-family: Roboto;
    color: black;
    text-decoration: none;
    width: fit-content;
    height: 40px;
    text-align: center;
  }
  .btn-whats {
    padding: 10px 20px;
    box-shadow: 0 3px 6px 0 rgba(165, 165, 61, 0);
    border: solid 1.5px #29d369;
    background-color: #29d369;
    border-radius: 20px;
    font-family: Roboto;
    color: #fff;
    text-decoration: none;
    width: fit-content;
    height: 40px;
    /* button */
    text-align: center;
  }
}

.filter-white {
  filter: invert(99%) sepia(1%) saturate(0%) hue-rotate(149deg) brightness(105%)
    contrast(100%);
}
</style>