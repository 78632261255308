<template>
  <div class="row d-flex" style="background: #19191c">
    <div class="col-lg-4 d-none d-xl-block fixed-top p-0">
      <img :src="imgSolicitud" :style="{ height: '100vh' }" />
    </div>

    <div class="col d-flex align-items-center flex-column form vh-100">
      <!-- Header -->
      <div class="header__form">
        <div class="form__paginacion">
          <div class="form__paginacion__contador">
            <p>{{ `0${selectTab + 1}` }}</p>
          </div>
          <p class="form__pagnacion__pagina">
            {{ tabs[selectTab] }}
          </p>
        </div>
      </div>
      <!-- Form step one -->
      <div class="col-md-7 col-xl-5 col-11 container__contact">
        <div v-if="selectTab == 0">
          <ContactForm
            :ejecutado="ejecutado"
            v-for="(element, index) in dataContacto"
            :key="index"
            v-bind:title="element.title"
            v-bind:text="element.text"
          />
        </div>
        <!-- Form step two -->
        <ProjectDetailsForm
          v-if="selectTab == 1"
          type="franquisiatario"
          v-bind:tab="selectTab"
        />
        <!-- Form step three -->
        <AcercaDeForm
          v-if="selectTab == 2"
          type="franquisiatario"
          v-bind:tab="selectTab"
        />
        <!-- If previus components present some error, show it -->
        <p v-if="selectTab > 0 && selectTab < 3" class="text-error">
          {{ error }}
        </p>
        <!-- Thank u page -->
        <CorreoEnviado v-if="selectTab == 3" />

        <div class="row d-flex form-container-btn w-100 contact__button">
          <button
            v-if="tabs.length - 2 == selectTab && enviar"
            class="btn-init-link btn-form mb-lg-0"
            style="width: 10rem"
            @click.native="gForm"
            v-on:click="submit"
          >
            <b>Enviar</b>
            <img
              src="@/assets/icons/Inversionista/icono-flecha-der.svg"
              width="40px"
            />
          </button>
          <button
            v-else-if="selectTab < tabs.length - 2"
            class="btn-init-link btn-form mb-lg-0"
            style="width: 15rem"
            @click.native="gAnalytics"
            v-on:click="changeTab"
          >
            <b>Siguiente</b>
            <img
              src="@/assets/icons/Inversionista/icono-flecha-der.svg"
              width="40px"
            />
          </button>
        </div>
      </div>
    </div>
    <!-- Google Tag Manager (noscript) -->
    <noscript
      ><iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-MD3HMDH"
        height="0"
        width="0"
        style="display: none; visibility: hidden"
      ></iframe
    ></noscript>
    <!-- End Google Tag Manager (noscript) -->
  </div>
</template>

<script>
import ImgSolicitud from "@/assets/img/Formulario/img-solicitud-franquicia.jpg";
import ContactForm from "../components/ContactForm.vue";
import CorreoEnviado from "../components/CorreoEnviado.vue";
import ProjectDetailsForm from "@/components/ProjectDetailsForm.vue";
import AcercaDeForm from "@/components/AcercaDeForm.vue";

// If data was sent or content is different to franquiciatarioForm and url is correct
if (
  (localStorage.getItem("title") !== "franquiciatarioForm" &&
    location.pathname === "/franquiciatario/form/step-one") ||
  localStorage.getItem("enviado") !== null
) {
  localStorage.clear();
  // console.log('localStorage is clean for franquiciatario form');
}

export default {
  name: "FranquiciatarioForm",
  components: {
    ContactForm,
    ProjectDetailsForm,
    AcercaDeForm,
    CorreoEnviado,
  },
  props: {},
  data: function () {
    return {
      imgSolicitud: ImgSolicitud,
      ejecutado: false,
      error: null,
      dataContacto: [
        {
          title: "Solicitud Franquicia",
          text: "Nos interesa conocerte para poder darte una propuesta adecuada a tus intereses, ayudanos a llenar la siguiente información y nos pondremos en contacto para agendar una cita.",
        },
      ],
      // Change to 0 for normal working
      selectTab: 0,
      tabs: [
        "Contacto",
        "Detalles del proyecto",
        "Acerca de ti",
        "Correo Enviado",
      ],
      enviar: true,
    };
  },
  computed: {},
  destroyed: function () {
    localStorage.clear();
  },
  created: function () {
    this.reloadTab();
    // When user make click on back button browser, back to previus step
    window.addEventListener("popstate", this.backStep);
  },
  mounted: function () {
    this.reloadTab();
  },
  methods: {
    reloadTab: function () {
      // if page is reload, load current tab
      if (
        location.pathname === "/franquiciatario/form/step-two" &&
        localStorage.getItem("title") !== null
      ) {
        this.selectTab = 1;
      } else if (
        location.pathname === "/franquiciatario/form/step-three" &&
        localStorage.getItem("title") !== null
      ) {
        this.selectTab = 2;
      } else {
        this.selectTab = 0;
      }
    },
    backStep: function () {
      this.selectTab =
        location.pathname === "/franquiciatario/form/step-two"
          ? 1
          : location.pathname === "/franquiciatario/form/step-three"
          ? 2
          : 0;
      // console.log("selectTab "+ this.selectTab);
    },
    changeTab: function () {
      this.error = "";
      // Check fields form step 1
      if (localStorage.getItem("validado") === "true" && this.selectTab === 0) {
        localStorage.setItem("validado", "false");
        this.selectTab += 1;
        // Check fields form step 2
      } else if (
        localStorage.getItem("validado") === "true" &&
        this.selectTab === 1
      ) {
        localStorage.setItem("validado", "false");
        this.selectTab += 1;
      } else {
        this.ejecutado = true;
        this.error = "Llene todos los campos";
        // console.log("Campos vacios");
      }
    },
    gForm() {
      this.$gtag.event("FormularioEnviado", {
        event_category: "envio formulario",
        event_label: "FormularioEnviado",
        value: 101,
      });
      // console.log("evento converiopn google");
    },
    submit: function () {
      let dataToSend = {};
      // Personal Data
      dataToSend.title = localStorage.getItem("title");
      dataToSend.formStep1 = JSON.parse(localStorage.getItem("formStep1"));
      dataToSend.formStep2 = JSON.parse(localStorage.getItem("formStep2"));
      dataToSend.formStep3 = JSON.parse(localStorage.getItem("formStep3"));

      // Check fields form step 3
      if (localStorage.getItem("validado") === "true" && this.selectTab === 2) {
        localStorage.setItem("enviado", "true");
        this.enviar = false;
        const axios = require("axios");
        console.log(`This is ${dataToSend.title}`);
        axios
          .post(
            "https://api.inversion.lamezcantina.mx/inversion/franquicia",
            dataToSend
          )
          // .post("http://localhost:3000/inversion/franquicia", dataToSend)
          .then((result) => {
            // console.log("Resultado: ");
            console.log(result);
            this.selectTab = 3;
            // console.log(this.selectTab);
            localStorage.clear();
          })
          .catch((error) => {
            console.log(error);
            this.enviar = true;
          });

        this.$gtag.event("LeadFranquiciatario", {
          event_category: "envio formulario",
          event_label: "LeadFranquiciatario",
          value: 101,
        });
        // console.log("Evento de conversion franquiciatario");
        // console.log("Sent data...");
      } else {
        this.enviar = true;
        // console.log("No estoy enviando");
        this.error = "LLene todos los campos";
      }
    },
    gAnalyticsTab() {
      if (this.selectTab == 1) {
        this.$gtag.event("franquiciatario/form/tab", {
          event_category: "cambio de tab",
          event_label: "franquiciatario/form/tab2",
          value: 15,
        });
      } else if (this.selectTab == 2) {
        this.$gtag.event("franquiciatario/form/tab", {
          event_category: "cambio de tab",
          event_label: "franquiciatario/form/tab2",
          value: 16,
        });
      }
    },
  },
};
</script>