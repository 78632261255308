<template>
  <div class="rendimiento p-4 row" style="background-color: #19191c">
    <div class="rendimiento__info col">
      <h5 class="sub-title rendimiento__info__sub">
        Calculadora de rendimiento
      </h5>
      <h1 class="rendimiento__info__title">
        Calcula tu rendimiento anual en base a tu inversión
      </h1>
      <p class="rendimiento__info__texto">
        Es muy importante conocer la información financiera antes de emprender
        un negocio, por eso te compartimos esta herramienta con la que puedes
        obtener un estimado del rendimiento de tu inversión
      </p>
      <router-link
        tag="button"
        @click="gAnalytics"
        class="btn-init-link my-3 d-none d-sm-block d-md-block"
        :to="`inversionista/form`"
      >
        <b>Quiero mi franquicia</b>
      </router-link>
    </div>
    <div class="rendimiento__calculadora col">
      <CardCalculadora />
      <router-link
        class="btn-process-sm btn-init-link d-inline d-sm-none"
        @click="gAnalytics"
        to="inversionista/form"
      >
        <b>Quiero mi franquicia</b>
        <img
          src="@/assets/icons/Inversionista/icono-flecha-der.svg"
          width="40px"
        />
      </router-link>
    </div>
  </div>
</template>

<script>
import CardCalculadora from "../components/Card/CardCalculadora.vue";

export default {
  name: "Rendimiento",
  components: {
    CardCalculadora,
  },
  methods: {
    gAnalytics() {
      this.$gtag.event("inversionista/form", {
        event_category: "Cambio de pagina",
        event_label: "inversionista/form",
        value: 4,
      });
    },
  },
};
</script>

