<template>
  <div class="d-flex flex-column align-items-center w-100 contactform">
    <h1 class="contact__h1">{{ title }}</h1>
    <p class="text-center contact__p">{{ text }}</p>

    <div class="mb-4" style="width: 100%">
      <p class="form-label">Nombre</p>
      <input
        type="text"
        class="input-init w-100"
        id="formGroupExampleInput"
        placeholder="Tu nombre"
        v-model="nombre"
        v-on:change="guardarDatos"
        required
      />
      <div v-if="ejecutado" class="contactform__errores">
        <p v-if="nombre === ''" class="text-error">
          Campo de nombre requerido
        </p>
      </div>
    </div>

    <div class="mb-4" style="width: 100%">
      <p class="form-label">Apellidos</p>
      <input
        type="text"
        class="input-init w-100"
        id="formGroupExampleInput"
        placeholder="Tus apellidos"
        v-model="apellidos"
        v-on:change="guardarDatos"
        required
      />
      <div v-if="ejecutado" class="contactform__errores">
        <p v-if="apellidos === ''" class="text-error">
          Campo de apellidos requerido
        </p>
      </div>
    </div>

    <div class="mb-4" style="width: 100%">
      <p class="form-label">WhatsApp</p>
      <input
        type="number"
        class="input-init w-100"
        id="formGroupExampleInput"
        placeholder="10 digitos"
        v-model="whatsapp"
        v-on:change="guardarDatos"
      />
      <div v-if="ejecutado" class="contactform__errores">
        <p v-if="whatsapp === ''" class="text-error">
          Campo de whatsapp requerido
        </p>
        <p v-if="whatsapp.length != 10 && whatsapp != ''" class="text-error">
          Ingrese un numero de 10 digitos
        </p>
      </div>
    </div>

    <div class="mb-4" style="width: 100%">
      <p class="form-label">Correo</p>
      <input
        type="email"
        pattern=".+@beststartupever.com"
        class="input-init w-100"
        id="formGroupExampleInput"
        placeholder="Tu correo electronico"
        v-model="correo"
        v-on:change="guardarDatos"
        required
      />
      <div v-if="ejecutado" class="contactform__errores">
        <p v-if="correo === ''" class="text-error">
          Campo de correo requerido
        </p>
        <p v-if="correoInvalido == true && correo != ''" class="text-error">
          Formato de correo invalido
        </p>
      </div>
    </div>
  </div>
</template>

<script>
localStorage.setItem("validado", "false");

export default {
  name: "ContactForm",
  data: function () {
    history.replaceState(null, "", "step-one")
    return {
      nombre: "",
      apellidos: "",
      whatsapp: "",
      correo: "",
      validado: "false",
      correoInvalido: false,
    };
  },
  components: {},
  props: {
    title: String,
    text: String,
    ejecutado: Boolean,
  },
  mounted: function () {
    let formData = JSON.parse(localStorage.getItem("formStep1"));
    // Refill fields step 1
    
    if (formData !== null) {
      this.nombre = formData.firstName;
      this.apellidos = formData.lastName;
      this.whatsapp = formData.phone;
      this.correo = formData.email;
      localStorage.setItem("validado", "true");
    }
  }, 
  methods: {
    guardarDatos: function () {
      localStorage.setItem("title", location.pathname === "/franquiciatario/form/step-one" ? "Franquiciatario" : "Inversionista")
      // console.log(location.pathname);
      // console.log(location.pathname === "/franquiciatario/form/step-one");
      let stepOne = {};
      // if no exist formStep1 in localStorage, create it
      if (localStorage.getItem("formStep1") === null ) {
        localStorage.setItem("formStep1", JSON.stringify(stepOne));
        // console.log("Step 1 created in localStorage");
      }
      // Get saved data from localStorage
      stepOne = JSON.parse(localStorage.getItem("formStep1"));
      
      stepOne.firstName = this.nombre;
      stepOne.lastName = this.apellidos;
      stepOne.phone = this.whatsapp;
      stepOne.email = this.correo;
      // Save data in localStorage
      localStorage.setItem("formStep1", JSON.stringify(stepOne));
      // console.log("Step 1 data saved");
      
      // Checking form fields 
      if (
        stepOne.firstName != "" &&
        stepOne.lastName != "" &&
        stepOne.phone.length === 10 &&
        this.validateEmail(this.correo)
      ) {
        this.correoInvalido = false;
        this.validado = "true";
      } else {
        this.validado = "false";
        if (!this.validateEmail(this.correo)) {
          this.correoInvalido = true;
        } else {
          this.correoInvalido = false;
        }
      }

      localStorage.removeItem("validado");
      localStorage.setItem("validado", this.validado);
      // console.log("Full fields? "+this.validado);
    },
    validateEmail(email) {
      const re = /\S+@\S+\.\S+/;
      return re.test(String(email).toLowerCase());
    },
  },
};
</script>
