<template>
  <div class="carousel-mobile p-4">
    <div v-if="tab === 0" class="col row">
      <div class="col-md-6 col-xs-1">
        <img
          src="@/assets/img/home/sucursales/1.jpg"
          width="100%"
          style="border-radius: 5px"
        />
      </div>
      <div class="col-md-6 col-xs-12 d-flex flex-column">
        <div class="row">
          <div class="col-6 p-3">
            <img
              src="@/assets/img/home/sucursales/2.jpg"
              width="100%"
              style="border-radius: 5px"
            />
          </div>
          <div class="col-6 p-3">
            <img
              src="@/assets/img/home/sucursales/3.jpg"
              width="100%"
              style="border-radius: 5px"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-6 p-3 d-none d-md-block">
            <img
              src="@/assets/img/home/sucursales/4.jpg"
              width="100%"
              style="border-radius: 5px"
            />
          </div>
          <div class="col-6 p-3 d-none d-md-block">
            <img
              src="@/assets/img/home/sucursales/5.jpg"
              width="100%"
              style="border-radius: 5px"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="tab === 1" class="col">
      <div class="col-md-6 col-xs-1">
        <img
          src="@/assets/img/home/experienciamezcalera/1.jpg"
          width="100%"
          style="border-radius: 5px"
        />
      </div>
      <div class="col-md-6 col-xs-12 d-flex flex-column">
        <div class="row">
          <div class="col-6 p-3">
            <img
              src="@/assets/img/home/experienciamezcalera/2.jpg"
              width="100%"
              style="border-radius: 5px"
            />
          </div>
          <div class="col-6 p-3">
            <img
              src="@/assets/img/home/experienciamezcalera/3.jpg"
              width="100%"
              style="border-radius: 5px"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-6 p-3 d-none d-md-block">
            <img
              src="@/assets/img/home/experienciamezcalera/4.jpg"
              width="100%"
              style="border-radius: 5px"
            />
          </div>
          <div class="col-6 p-3 d-none d-md-block">
            <img
              src="@/assets/img/home/experienciamezcalera/5.jpg"
              width="100%"
              style="border-radius: 5px"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="tab === 2" class="col">
      <img src="@/assets/img/Experiencia/MenuOne.jpg" alt="" width="100%" />
    </div>
    <div v-if="tab === 3" class="col">
      <video width="100%" autoplay loop muted preload>
        <source
          src="@/assets/img/Experiencia/AmbienteVideo.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    </div>
  </div>
</template>

<script>
export default {
  name: "Carrousel",
  props: {
    tab: Number,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.carousel-mobile {
  min-height: 450px;
}
</style>
