<template>
  <div
    class="row d-flex align-items-center justify-content-center pb-5 pt-5"
    style="background-color: #19191c"
  >
    <div class="row d-flex justify-content-center align-items-center">
      <div
        class="
          col-md-6
          d-flex
          justify-content-center
          flex-column
          align-items-center
          col-xs-12
          pt-3
        "
      >
        <h5 class="sub-title text-center modelo-inversion-titulo">
          ¿Como funciona?
        </h5>
        <h1 class="text-center">Modelo de CrowdFunding</h1>
        <p class="text-center">
          Diversificamos y reducimos el riesgo generando rendimientos
          consistentes a nuestros inversionistas.
        </p>
      </div>
    </div>
    <div class="row">
      <div
        class="
          col-md-6 col-xs-12
          d-flex
          align-items-center
          justify-content-center
        "
      >
        <img
          src="@/assets/icons/Inversionista/img-modelo-inversion.svg"
          width="70%"
          style="border-radius: 5px"
        />
      </div>
      <div class="col-md-6 col-xs-12 align-items-center">
        <div class="col-md-8 col-xs-11 m-5 align-items-center">
          <p>
            Nuestro modelo se basa en socios inversionistas que aportan capital
            para construir y operar sucursales de la Mezcantina, a cambio
            reciben acciones proporcionales a su inversión.
          </p>
          <p>
            Estas acciones generan rendimientos trimestrales en base a las
            utilidades que generan las sucursales.
          </p>
          <p>
            El proyecto de construcción, operación, supervisión y administración
            de las sucursales lo realizamos nosotros, a trávez de un equipo
            multidisciplinario, sistema de información y herramienta de
            inteligencia de negocios.
          </p>
          <p>
            Entregamos a nuestros inversionistas reportes trimestrales que
            muestran avances, ganancias y otros indicadores clave y al cierre de
            cada trimestre les depositamos sus ganancias.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModeloInversion",
  props: {},
};
</script>
