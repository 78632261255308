<template>
  <div class="w-100 mb-3">
    <p class="form-label">{{ question }}</p>
    <input
      class="input-init w-100"
      v-if="type == 'text'"
      type="text"
      v-model="value"
      v-on:change="saveAnswer"
      :placeholder="placeholder"
      required
    />

    <div
      v-if="type == 'options'"
      class="d-flex flex-row justify-content-between flex-wrap w-100">
      <div
        v-for="(element, index) in options"
        :key="index"
        :class="`radio-item d-flex justify-content-between mb-3 
          ${value == element.text ? 'radio-item-selected' : ''}
          ${options.length % 2 != 0 && index == options.length - 1 ? 'w-100' : ''}`"
      >
        <img :src="element.icon" style="width: 1.5rem" class="m-1" />
        <span class="d-flex flex-fill align-items-center process__input__texto">
          {{ element.text }}
        </span>

        <div class="process__input--div">
          <input
            type="radio"
            class="process__input"
            aria-label="..."
            v-model="value"
            :value="element.text"
            v-on:change="saveAnswer"
            required
          />
        </div>
      </div>
    </div>

    <div v-if="type == 'textarea'" class="w-100">
      <textarea
        class="text-init w-100"
        cols="30"
        v-model="value"
        :placeholder="placeholder"
        v-on:change="saveAnswer"
        required
      ></textarea>
    </div>
  </div>
</template>

<script>
localStorage.setItem("validado", 'false');

export default {
  name: "QuestionItem",
  components: {},
  props: {
    index: Number,
    numQuestions: Number, 
    type: String,
    tab: Number,
    question: String,
    options: Array,
    placeholder: String,
    icon: String,
  },
  data: function () {
    return {
      value: "",
    };
  },
  computed: {},
  created: function () {
    this.refillFields();
  },
  mounted: function () {
    this.refillFields();
  },
  methods: {
    refillFields: function () {
      let formStep = this.tab === 1 ? "formStep2" : "formStep3"  

      if (localStorage.getItem("title") === "franquiciatarioForm" && localStorage.getItem(formStep) || localStorage.getItem("title") === "inversionistaForm" && localStorage.getItem(formStep) !== null) {
        let formData = JSON.parse(localStorage.getItem(formStep));

        let radios = document.querySelectorAll("input[type=radio]")
        
        if (formData[`p${this.index}`]!== undefined) {
          let value = formData[`p${this.index}`].split(" ")
          value.shift()
          // console.log(`p${this.index}: ${value.join(" ")}`)
          this.value = value.join(" ")
          
          radios.forEach((item) => {
            if (item.value === value.join(" ")) {
              item.click()
            }
          })
        }
        localStorage.setItem("validado", "true");
      }
    },
    saveAnswer: function () {
      localStorage.setItem("validado", 'false');

      if (this.tab === 1) {
        // save step 2 data
        let stepTwo = {};
        // if no exist formStep2 in localStorage, create it
        if (localStorage.getItem("formStep2") === null ) {
          localStorage.setItem("formStep2", JSON.stringify(stepTwo));
          // console.log("Step 2 created into localStorage");
        }
        // Get saved data from localStorage
        stepTwo = JSON.parse(localStorage.getItem("formStep2"));

        stepTwo[`p${this.index}`] = `${this.value}`
        // Save data in localStorage
        localStorage.setItem("formStep2", JSON.stringify(stepTwo))
        // Check if fields are full
        if(Object.keys(stepTwo).length === this.numQuestions){
          localStorage.removeItem("validado");
          localStorage.setItem("validado", 'true');
        }
        // console.log("Step 2 data saved");
        // console.log(`Full fields? ${localStorage.getItem("validado")}`);
      } else if (this.tab === 2) {
        // save step 3 data
        let stepThree = {};
        // if no exist formStep2 in localStorage, create it
        if (localStorage.getItem("formStep3") === null ) {
          localStorage.setItem("formStep3", JSON.stringify(stepThree));
          // console.log("Step 2 created into localStorage");
        }
        // Get saved data from localStorage
        stepThree = JSON.parse(localStorage.getItem("formStep3"));

        stepThree[`p${this.index}`] = `${this.value}`
        if (this.value === "No") {
          stepThree[`p${this.index+1}`] = ` `
        }
        // Save data in localStorage
        localStorage.setItem("formStep3", JSON.stringify(stepThree))
        // Check if fields are full
        if(Object.keys(stepThree).length === this.numQuestions){
          localStorage.removeItem("validado");
          localStorage.setItem("validado", 'true');
        }
        // console.log("Step 3 data saved");
        // console.log(`Full fields? ${localStorage.getItem("validado")}`);
      }
    }
  },
};
</script>
