<template>
  <div
    class="panel-top d-flex align-items-center vh-100 position-relative"
    :style="imgUrl"
  >
    <div class="panel-top-bg position-absolute top-0"></div>
    <div class="panel-top-content container mx-3 mx-md-0">
      <div class="col-md-5 col-lg-8 home-titulo paneltop">
        <h5 class="sub-title paneltop__h5">{{ subtitle }}</h5>
        <h1 class="home__title paneltop__h1">{{ title }}</h1>
        <p class="my-lg-5 paneltop__text">{{ text }}</p>
        <router-link
          v-if="link !== 'home'"
          tag="button"
          @click.native="gAnalytics"
          class="btn-init-link panel-top__btn"
          :to="`/franquiciatario/form/`"
        >
          <b>{{ buttonText || "Quiero mi franquicia" }}</b>
        </router-link>
        <a
          v-else
          type="button"
          href="#Invertir"
          class="btn-init-link home-btn panel-top__btn"
        >
          <b>Quiero mi franquicia</b>
          <img
            src="@/assets/icons/Inversionista/icono-flecha-der.svg"
            width="40px"
          />
        </a>
        <div
          v-if="title === 'Al adquirir una franquicia de La Mezcantina'"
          class="d-flex mt-5 flex-wrap flex-md-nowrap"
        >
          <div class="d-flex align-items-center mt-3 mt-md-0">
            <img
              class="col me-3"
              src="@/assets/icons/Inversionista/icono-mail.svg"
              width="40px"
            />
            <b
              class="color-w contacto__correo px-3 py-2 rounded-pill"
              style="background: #19191c"
              >franquicias@lamezcantina.mx</b
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PanelTop",
  props: {
    title: String,
    subtitle: String,
    text: String,
    buttonText: String,
    background: String,
    link: String,
  },
  computed: {
    imgUrl() {
      return {
        "background-image": "url('" + this.background + "')",
      };
    },
    currentRouteName() {
      return this.$route.path;
    },
  },
  methods: {
    gAnalytics() {
      if (this.currentRouteName == "/inversionista") {
        this.$gtag.event("franquiciatario/form", {
          event_category: "Cambio de pagina",
          event_label: "franquiciatario/form",
          value: 3,
        });
      } else if (this.currentRouteName == "/franquiciatario") {
        this.$gtag.event("inversionista/form", {
          event_category: "Cambio de pagina",
          event_label: "inversionista/form",
          value: 4,
        });
      } else {
        console.log(this.btnLink);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.panel-top {
  &-content {
    z-index: 1;
  }
  &-bg {
    background: -moz-linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0.8) 43%,
      rgba(0, 0, 0, 0) 100%
    );
    background: -webkit-linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0.8) 43%,
      rgba(0, 0, 0, 0) 100%
    );
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0.8) 43%,
      rgba(0, 0, 0, 0) 100%
    );
    background-color: transparent;
    width: 100%;
    height: 100%;
  }
}
</style>