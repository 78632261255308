<template>
  <div class="calculadora">
    <img
      class="calculadora__icono"
      src="@/assets/icons/Inversionista/icono-calculadora.svg"
      alt="icono calculadora"
    />
    <h1 class="calculadora__titulo">Calculadora de rendimiento</h1>
    <p class="calculadora__subtitulo">Inversión inicial</p>
    <p class="calculadora__inversion">
      {{ value >= 1000 ? `${value / 1000}M` : `${value}K` }}
    </p>
    <input
      class="calculadora__barra"
      type="range"
      min="125"
      max="1250"
      step="25"
      v-model="value"
    />
    <div class="calculadora__rango">
      <p>125K</p>
      <p>1.25M</p>
    </div>
    <p class="calculadora__rendimientos">Rendimientos anuales</p>
    <h2 class="calculadora__ganancia">${{ rendimiento }} MNX</h2>
  </div>
</template>

<script>
export default {
  name: "CardCalculadora",
  data() {
    return {
      value: 800,
      gain: 1000,
    };
  },
  computed: {
    rendimiento: function () {
      return (this.value * 0.3 * this.gain)
        .toFixed(0)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      //.toFixed(2).replace(/\d(?=(\d{3})+\.)/g,'$&,');
    },
  },
};
</script>