<template>
  <div id="app">
    <Header v-if="!headerExcludedPages.includes($route.path)" />
    <router-view />

    <div
      v-if="!buttonExcludedPages.includes($route.path)"
      class="whats-container position-fixed d-block d-lg-none justify-content-center align-items-center"
    >
      <a
        class="btn-whats d-block fw-bolder"
        href="https://wa.me/523323121591"
        target="_blank"
      >
        <img
          class="filter-white"
          src="@/assets/icons/whatsapp.svg"
          alt=""
          width="45"
      /></a>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";

export default {
  name: "App",
  components: {
    Header,
  },
  data() {
    return {
      headerExcludedPages: [
        "/form",
        "/inversionista/form",
        "/franquiciatario/form",
      ],
      buttonExcludedPages: [
        "/inversionista/form/",
        "/franquiciatario/form/",
        "/franquiciatario/form/step-one",
        "/franquiciatario/form/step-two",
        "/franquiciatario/form/step-three",
        "/franquiciatario/form/thank-you",
      ],
    };
  },
};
</script>

<style lang="scss">
//@import "./assets/styles/app.css";

.whats-container {
  right: 16px;
  bottom: 20px;
  z-index: 9999;

  .btn-whats {
    padding: 10px;
    box-shadow: 0 3px 6px 0 rgba(165, 165, 61, 0);
    border: solid 1.5px #29d369;
    background-color: #29d369;
    border-radius: 50%;
    color: #fff;
    width: fit-content;
  }
}
</style>
