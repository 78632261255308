<template>
  <div class="card shadow mt-3 w-100">
    <button
      class="btn cardpregunta__header__btn"
      data-toggle="collapse"
      @click="showText"
    >
      <div class="card-header row d-flex align-items-center" id="headingOne">
        <p class="col-2 text-center justify-content-center">{{ numero }}</p>
        <p class="col-8">{{ pregunta }}</p>
        <b v-if="show" class="btn-pregunta col-2">-</b>
        <b v-else class="btn-pregunta col-2">+</b>
      </div>
    </button>
    <div
      id="collapseOne"
      class="collapse"
      :class="show ? 'show' : ''"
      aria-labelledby="headingOne"
      data-parent="#accordion"
      co
    >
      <div class="card-body" :v-for="text in texts">
        <ul class="preguntas">
          <li class="pregunta" v-for="text in texts" :key="text">
            {{ text }} <br /><br />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardVentajas",
  props: {
    numero: String,
    pregunta: String,
    texts: Array,
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    showText: function () {
      this.show = !this.show;
    },
  },
};
</script>