<template>
  <div class="row p-4 galeria">
    <div v-if="tab === 0" class="col">
      <div class="sucursales-row row py-2">
        <div class="col-lg-3">
          <img
            src="@/assets/img/Experiencia/SucursalesOne.jpg"
            width="100%"
            style="border-radius: 5px"
          />
        </div>
        <div class="col-lg-9">
          <img
            src="@/assets/img/Experiencia/SucursalesTwo.jpg"
            width="100%"
            style="border-radius: 5px"
          />
        </div>
      </div>

      <div class="sucursales-row row py-2">
        <div class="col-lg-4">
          <img
            src="@/assets/img/Experiencia/SucursalesThree.jpg"
            width="100%"
            style="border-radius: 5px"
          />
        </div>
        <div class="col-lg-6">
          <img
            src="@/assets/img/Experiencia/SucursalesFour.jpg"
            width="100%"
            style="border-radius: 5px"
          />
        </div>
        <div class="col-lg-2">
          <img
            src="@/assets/img/Experiencia/SucursalesFive.jpg"
            width="100%"
            style="border-radius: 5px"
          />
        </div>
      </div>
    </div>
    <div v-if="tab === 1" class="col row">
      <div class="col-md-6 col-xs-1">
        <img
          src="@/assets/img/home/experienciamezcalera/1.jpg"
          width="100%"
          style="border-radius: 5px"
        />
      </div>
      <div class="col-md-6 col-xs-12 d-flex flex-column">
        <div class="row">
          <div class="col-6 p-3">
            <img
              src="@/assets/img/home/experienciamezcalera/2.jpg"
              width="100%"
              style="border-radius: 5px"
            />
          </div>
          <div class="col-6 p-3">
            <img
              src="@/assets/img/home/experienciamezcalera/3.jpg"
              width="100%"
              style="border-radius: 5px"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-6 p-3 d-none d-md-block">
            <img
              src="@/assets/img/home/experienciamezcalera/4.jpg"
              width="100%"
              style="border-radius: 5px"
            />
          </div>
          <div class="col-6 p-3 d-none d-md-block">
            <img
              src="@/assets/img/home/experienciamezcalera/5.jpg"
              width="100%"
              style="border-radius: 5px"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="tab === 2" class="col row">
      <div class="col-lg-5 col-xl-4">
        <img
          src="@/assets/img/Experiencia/MenuOne.jpg"
          width="100%"
          style="border-radius: 5px"
        />
      </div>
      <div class="col-md-7">
        <div class="menu-row row">
          <div class="col-lg-12 px-3">
            <img
              src="@/assets/img/Experiencia/MenuTwo.jpg"
              width="100%"
              style="border-radius: 5px"
            />
          </div>
        </div>
        <div class="menu-row row">
          <div class="col-lg-5 ps-3">
            <img
              src="@/assets/img/Experiencia/MenuThree.jpg"
              width="100%"
              style="border-radius: 5px"
            />
          </div>
          <div class="col-lg-7 pe-3">
            <img
              src="@/assets/img/Experiencia/MenuFour.jpg"
              width="100%"
              style="border-radius: 5px"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="tab === 3" class="col row">
      <div class="col-lg-5">
        <img
          src="@/assets/img/Experiencia/AmbienteOne.jpg"
          width="100%"
          style="border-radius: 5px"
        />
      </div>
      <div class="col-md-7">
        <div class="menu-row row">
          <div class="col-lg-12 px-3">
            <img
              src="@/assets/img/Experiencia/AmbienteTwo.jpg"
              width="100%"
              style="border-radius: 5px"
            />
          </div>
        </div>
        <div class="menu-row row">
          <div class="col-lg-6 ps-3">
            <img
              src="@/assets/img/Experiencia/AmbienteThree.jpg"
              width="100%"
              style="border-radius: 5px"
            />
          </div>
          <div class="col-lg-6 pe-3">
            <img
              src="@/assets/img/Experiencia/AmbienteFour.jpg"
              width="100%"
              style="border-radius: 5px"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Carrousel",
  props: {
    tab: Number,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
@media (min-width: 999px) {
  .galeria {
    min-height: 640px;
  }
  .sucursales-row {
    min-height: 250px;
    // max-height: 250px;
    align-items: stretch;
    div {
      img {
        height: 100%;
      }
    }
  }
  .menu-row {
    min-height: 250px;
    max-height: 250px;
    align-items: stretch;
    div {
      img {
        height: 100%;
      }
    }
  }
}
</style>
