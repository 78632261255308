<template>
  <div class="w-100">
    <QuestionItem
      v-for="(element, index) in type == 'inversionista'
        ? questionsInversion
        : questionsFranquicia"
      :key="index"
      v-bind:index="index"
      v-bind:tab="tab"
      v-bind:numQuestions="type == 'inversionista'
        ? questionsInversion.length
        : questionsFranquicia.length"
      v-bind:type="element.type"
      v-bind:question="element.question"
      v-bind:options="element.options"
      v-bind:placeholder="element.placeholder"
    />
  </div>
</template>

<script>
import QuestionItem from "@/components/QuestionItem.vue";
import IconSi from "../assets/icons/Formulario/icono-si.svg";
import IconGerente from "../assets/icons/Formulario/icono-gerente.svg";
import IconBar from "../assets/icons/Formulario/icono-bar.svg";
import IconNo from "../assets/icons/Formulario/icono-no.svg";

export default {
  name: "AcercaDeForm",
  components: {
    QuestionItem,
  },
  props: {
    type: String,
    tab: Number
  },
  data: function () {
    if (location.pathname !== "/franquiciatario/form/step-three") {
      history.pushState(null, '', 'step-three');
    }
    return {
      questionsFranquicia: [
        {
          type: "options",
          question:
            "¿Cual es tu experiencia en el sector de restaurantes y bares?",
          options: [
            { text: "Ninguna, me gustaria adquirir experiencia", icon: IconSi },
            { text: "He trabajado en un restaurante o bar", icon: IconGerente },
            {
              text: "He administrado y operado uno o varios restauarante(s) o bar(es) ",
              icon: IconBar,
            },
          ],
        },
        {
          type: "options",
          question: "¿Has operado alguna franquicia anteriormente?",
          options: [
            { text: "Sí", icon: IconSi },
            { text: "No", icon: IconNo },
          ],
        },
        {
          type: "text",
          question: "Si tu respuesta fue sí, ¿Cuál?",
          placeholder: "Nombre del lugar",
        },
        {
          type: "text",
          question: "¿En qué empresa o negocio laboras actualmente?",
          placeholder: "Nombre del lugar",
        },
        {
          type: "text",
          question: "¿Cuál es el sector o giro de la empresa?",
          placeholder: "Sector o giro",
        },
        {
          type: "text",
          question: "¿Qué puesto desempeñas ahí?",
          placeholder: "Nombre del puesto",
        },
      ],
      questionsInversion: [
        {
          type: "options",
          question:
            "¿Cuál es tu experiencia en el sector de restaurantes y bares?",
          options: [
            { text: "Ninguna, me gustaría adquirir experiencia", icon: IconSi },
            { text: "He trabajado en un restaurante o bar", icon: IconGerente },
            {
              text: "He administrado y operado uno o varios restauarante(s) o bar(es) ",
              icon: IconBar,
            },
          ],
        },
        {
          type: "text",
          question: "¿En qué empresa o negocio laboras actualmente?",
          placeholder: "Nombre del lugar",
        },
        {
          type: "text",
          question: "¿Cuál es el sector o giro de la empresa?",
          placeholder: "Sector o giro",
        },
        {
          type: "text",
          question: "¿Qué puesto desempeñas ahí?",
          placeholder: "Nombre del puesto",
        },
      ],
    };
  },
  computed: {},
  methods: {},
};
</script>
