<template>
  <div
    class="d-flex row flex-column align-items-center pt-5 pb-5"
    :style="{ background: '#19191c', 'min-height': '90vh' }"
  >
    <div class="d-flex justify-content-center align-items-center">
      <div
        class="col-md-8 d-flex justify-content-center flex-column align-items-center col-xs-12 pt-3"
      >
        <h5 class="sub-title text-center">{{ subtitle }}</h5>
        <h1 class="text-center process-title">{{ title }}</h1>
      </div>
    </div>

    <div class="d-flex justify-content-between mb-3 w-100 px-4">
      <!-- <div class="grow-1" /> -->
      <button
        class="regular"
        style="width: 40px"
        v-on:click="backTab"
        :disabled="selectTab == 0"
      >
        <img
          v-show="selectTab != 0"
          src="@/assets/icons/Inversionista/icono-flecha-izq.svg"
          width="40px"
        />
      </button>
      <!-- disabled deshabilida boton > si se reduce numero desaparecen cartas -->
      <button
        class="regular d-none d-xxl-block"
        v-on:click="changeTab"
        :disabled="dataCards.length - 4 == selectTab"
      >
        <img
          v-show="selectTab != 4"
          src="@/assets/icons/Inversionista/icono-flecha-der-circle.svg"
          width="40px"
        />
      </button>

      <button
        class="regular d-block d-xxl-none"
        v-on:click="changeTab"
        :disabled="dataCards.length - 1 == selectTab"
      >
        <img
          v-show="selectTab != 7"
          src="@/assets/icons/Inversionista/icono-flecha-der-circle.svg"
          width="40px"
        />
      </button>
    </div>

    <div class="d-flex row justify-content-center div__processlinecard">
      <!-- <div class="grow-1" /> -->

      <Card
        :title="dataCards[0].title"
        :text="dataCards[0].text"
        :icon="dataCards[0].icon"
        :stepNumber="dataCards[0].stepNumber"
        :selected="dataCards[0].selected"
        :action="dataCards[0].action"
        :btnLink="
          type == 'inv' ? dataCardsInv[0].btnLink : dataCards[0].btnLink
        "
        :btnText="dataCards[0].btnText"
        class="d-none d-xxl-block card-principal-process"
      />

      <!-- eslint-disable  -->
      <Card
        v-for="(element, index) in type == 'inv' ? dataCardsInv : dataCards"
        :key="index"
        v-if="index == selectTab + 1"
        v-bind:title="element.title"
        v-bind:text="element.text"
        v-bind:icon="element.icon"
        v-bind:stepNumber="element.stepNumber"
        v-bind:selected="element.selected"
        v-bind:action="element.action"
        v-bind:btnLink="element.btnLink"
        v-bind:btnText="element.btnText"
        class="d-none d-xxl-block"
        :id="direction"
      />

      <!-- eslint-disable  -->
      <Card
        v-for="(element, index) in type == 'inv' ? dataCardsInv : dataCards"
        :key="index"
        v-if="index == selectTab + 2"
        v-bind:title="element.title"
        v-bind:text="element.text"
        v-bind:icon="element.icon"
        v-bind:stepNumber="element.stepNumber"
        v-bind:selected="element.selected"
        v-bind:action="element.action"
        v-bind:btnLink="element.btnLink"
        v-bind:btnText="element.btnText"
        class="d-none d-xxl-block"
        :id="direction"
      />
      <!-- eslint-disable  -->
      <Card
        v-for="(element, index) in type == 'inv' ? dataCardsInv : dataCards"
        :key="index"
        v-if="index == selectTab + 3"
        v-bind:title="element.title"
        v-bind:text="element.text"
        v-bind:icon="element.icon"
        v-bind:stepNumber="element.stepNumber"
        v-bind:selected="element.selected"
        v-bind:action="element.action"
        v-bind:btnLink="element.btnLink"
        v-bind:btnText="element.btnText"
        class="d-none d-xxl-block"
        :id="direction"
      />

      <!-- eslint-disable  -->
      <Card
        v-for="(element, index) in type == 'inv' ? dataCardsInv : dataCards"
        :key="index"
        v-if="index == selectTab"
        v-bind:title="element.title"
        v-bind:text="element.text"
        v-bind:icon="element.icon"
        v-bind:stepNumber="element.stepNumber"
        v-bind:selected="element.selected"
        v-bind:action="element.action"
        v-bind:btnLink="element.btnLink"
        v-bind:btnText="element.btnText"
        class="d-block d-xxl-none"
        :id="direction"
      />
    </div>
    <router-link
      class="btn-process-sm btn-init-link px-4 d-inline d-sm-none"
      @click.native="gAnalytics"
      :to="btnLink"
    >
      <b>Quiero mi franquicia</b>
      <img
        src="@/assets/icons/Inversionista/icono-flecha-der.svg"
        width="40px"
      />
    </router-link>
  </div>
</template>

<script>
import Card from "../components/Card/CardProcessLine.vue";
import Solicitud from "@/assets/icons/franquiciatario/icono-agendar-enviar-solicitud.svg";
import AgendarCita from "@/assets/icons/franquiciatario/icono-agendar-cita.svg";
import RevisionDeAcuerdo from "@/assets/icons/Inversionista/icono-revision-acuerdo.svg";
import FirmaDeAcuerdo from "@/assets/icons/Inversionista/icono-firmar-acuerdo.svg";
import PresentaProyecto from "@/assets/icons/franquiciatario/icono-presentacion-proyecto.svg";
import ProcesoDeConstruccion from "@/assets/icons/franquiciatario/icono-proceso-construcion.svg";
import Inauguracion from "@/assets/icons/franquiciatario/icono-inauguracion.svg";
import BrindamosAsistencia from "@/assets/icons/franquiciatario/icono-brindamos-asistencia.svg";

export default {
  name: "ProcessLine",
  components: {
    Card,
  },
  props: {
    title: String,
    subtitle: String,
    type: String,
    btnLink: String,
  },
  data() {
    return {
      dataCards: [
        {
          title: "Mándanos tu solicitud",
          text: "Completa el siguiente formulario con todos tus datos.",
          icon: Solicitud,
          selected: true,
          action: true,
          stepNumber: "01",
          btnLink: "/franquiciatario/form/",
          btnText: "Formulario",
        },
        {
          title: "Agendamos una cita",
          text: "Puede ser virtual o presencial, te mostramos las sucursales y resolvemos todas tus dudas acerca del modelo de neogcio.",
          icon: AgendarCita,
          stepNumber: "02",
        },
        {
          title: "Revision de acuerdo",
          text: "Te enviamos el acuerdo de inversion para que puedas reviar todos los detalles.",
          icon: RevisionDeAcuerdo,
          stepNumber: "03",
        },
        {
          title: "Firmamos acuerdo",
          text: "Se firma el contrato de arrendamiento del local y el contrato de la franquicia de La Mezcantina.",
          icon: FirmaDeAcuerdo,
          stepNumber: "04",
        },
        {
          title: "Se presenta el proyecto",
          text: "Se muestran renders y presupuestos unitarios de toda la inversion, se evalua posibles cambios y e evalua proyecto.",
          icon: PresentaProyecto,
          stepNumber: "05",
        },
        {
          title: "Proceso de construccion",
          text: "A la par del proceso de construccion y remodelacion nos encargamos de capacitarte a ti, a tu gerente y tu equipo de trabajo inicial.",
          icon: ProcesoDeConstruccion,
          stepNumber: "06",
        },
        {
          title: "Inauguración",
          text: "Te entregamos tu Mezcantina y te acompañamos en el proceso de inauguración y apertura hasta que estes listo para caminar solo.",
          icon: Inauguracion,
          stepNumber: "07",
        },
        {
          title: "Brindamos asistencia",
          text: "te daremos acompañamiento en distintas areas del negocio durante toda la vigencia del contarto para ayudarte a obtener los mejores resultados de tu Mezcantina.",
          icon: BrindamosAsistencia,
          stepNumber: "08",
        },
      ],
      dataCardsInv: [
        {
          title: "Mándanos tu solicitud",
          text: "Completa el siguiente formulario con todos tus datos.",
          icon: Solicitud,
          selected: true,
          action: true,
          stepNumber: "01",
          btnLink: "/inversionista/form",
          btnText: "Formulario",
        },
        {
          title: "Agendamos una cita",
          text: "Puede ser virtual o presencial, te mostramos las sucursales y resolvemos todas tus dudas acerca del modelo de neogcio.",
          icon: AgendarCita,
          stepNumber: "02",
        },
        {
          title: "Revision de acuerdo",
          text: "Te enviamos el acuerdo de inversion para que puedas reviar todos los detalles.",
          icon: RevisionDeAcuerdo,
          stepNumber: "03",
        },
        {
          title: "Firmamos acuerdo",
          text: "Firmamos el acuerdo de inversión y se aparta tu lugar con el 10% del monto a invertir.",
          icon: FirmaDeAcuerdo,
          stepNumber: "04",
        },
        {
          title: "Liquidacion",
          text: "Una vez que los lugares estén completos se liquidan el 90% restante de la inversión.",
          icon: PresentaProyecto,
          stepNumber: "05",
        },
        {
          title: "Adquicion de acciones",
          text: "Fimramos la adquicion de acciones de las sucursales en las que invierte",
          icon: ProcesoDeConstruccion,
          stepNumber: "06",
        },
        {
          title: "Proceso de construccion",
          text: "Iniciamos con el proceso de construcción, remodelación y capacitamos al equipo de trabajo.",
          icon: Inauguracion,
          stepNumber: "07",
        },
        {
          title: "Reportes trimestrales",
          text: "Te enviamos reportes trimestrales y te despositamos tus utilidades cada cierre de año.",
          icon: BrindamosAsistencia,
          stepNumber: "08",
        },
      ],
      selectTab: 0,
      direction: null,
    };
  },
  computed: {},
  methods: {
    changeTab: function () {
      //debugger;
      this.selectTab += 1;
      this.direction = "right";

      console.log(this.direction);
    },
    backTab: function () {
      //debugger;
      this.selectTab -= 1;
      this.direction = "left";

      console.log(this.direction);
    },
    gAnalytics() {
      if (this.btnLink == "/inversionista") {
        this.$gtag.event("franquiciatario/form", {
          event_category: "Cambio de pagina",
          event_label: "franquiciatario/form",
          value: 3,
        });
      } else if (this.btnLink == "/franquiciatario") {
        this.$gtag.event("inversionista/form", {
          event_category: "Cambio de pagina",
          event_label: "inversionista/form",
          value: 4,
        });
      } else {
        console.log(this.btnLink);
      }
    },
  },
};
</script>
